<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <span class="bq">续住日期：</span>
            <!--<img src="@/assets/xzrq.png" class="icon" />
            <input type="text" class="datespace" v-model="dateSpace" ref="calendarTigger" placeholder="请选择续住时间" readonly>-->
            <input type="date" id="xzdate" class="datespace" v-model="xzdate"/>
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交</div>
        <div class="button1 primary mt" @click="goback">取消</div>    
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
import moment from "moment";
export default {
  created() {
    //this.type = this.$route.query.type;
    //this.unionid = this.$route.query.unionid;
    this.room_code = this.$route.query.room_code;
    this.checkout_date = this.$route.query.checkout_date;

    this.nowdate = this.getdate();
    //this.maxdate = this.computeYmd(this.nowdate,1);
    //this.xzdate = moment().add(1, 'day').format('YYYY-MM-DD');
    this.xzdate = moment(this.checkout_date).add(1, 'days').format('YYYY-MM-DD');
  },
  /*mounted() {
    this.initCalendar()
  },*/
  data() {
    return {
      type: '',
      unionid: '',
      room_code: '',
      xzdate: '',
      startdate: '',
      enddate: '',
      nowdate: '',
      maxdate: '',
      dateSpace: '',
      srole: true,
      zonename: '',
      checkout_date: '',
      state: false,
      remark: ''
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/tourist/travelers_query', query:{}});
    },
    getdate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },
    /*initCalendar() {
      this.$calendarSwitch({
          element: this.$refs.calendarTigger,
          monthSize: 2,
          minDate: this.nowdate,
          maxDate: this.maxdate,
          onSuccess: (data) => {
            this.dateSpace = data.start_date +'~'+ data.end_date;
            this.startdate = data.start_date;
            this.enddate = data.end_date;

            var a = moment(this.enddate);
            var b = moment(this.startdate);
            var c = a.diff(b, 'days')
            this.days = c;

            console.log(this.dateSpace);
          },
          onError: function(msg) {
              console.log(msg)
          }
      });
    },
    computeYmd(val, n) {// 获取给定日期的 n个月后的日期  给定日期格式如：val = 2019-02-26; n = 多少个月
      let str = val.split('-');
      let d = new Date(str[0], str[1], str[2]);
      // 因为getMonth()获取的月份的值只能在0~11之间所以我们在进行setMonth()之前先给其减一
      d.setMonth((d.getMonth()-1) + n);
      let yy1 = d.getFullYear();
      let mm1 = d.getMonth()+1;
      let dd1 = d.getDate()-1;
      if(dd1 == '00'){
        mm1 = parseInt(mm1)-1;
        let new_date = new Date(yy1,mm1,1);
        dd1 = (new Date(new_date.getTime()-1000*60*60*24)).getDate()
      }
      if (mm1 < 10 ) {
        mm1 = '0' + mm1;
      }
      if (dd1 < 10) {
        dd1 = '0' + dd1;
      }
      return yy1 + '-' + mm1 + '-' + dd1;
    },*/
    async submit() {    
      
      if(this.state){
        return false;
      }
      this.state = true;
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_roomaddday',
        qs.stringify({ 
          roomcode: this.room_code,
          daytime: this.xzdate
        })
      );
      if (data.flag === '0') {
        this.state = false;
        //Toast(data.message);
        Dialog.confirm({
          showCancelButton: false,
          message: data.message+"，退出操作页面！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
        //this.$router.replace({path:'/boss/region_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
      } else {
        this.state = false;
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.bq {
  color: rgb(120, 120, 121);
  font-size: 28px;
  width: 200px; 
  height: 50px; 
  line-height: 50px;
  vertical-align: middle;
}
.datespace {
  width: 100%; 
  height: 50px; 
  line-height: 50px;
  padding-top: 2.5px;
  color: rgb(168, 169, 170);
  font-size: 28px;
  /* 清除默认边框 */
  border: 0;
  /* 清除默认的箭头样式 */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  padding-left: 20px;
  cursor: pointer;  
  &.bt {
    width: 110px; 
  }          
}

.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>